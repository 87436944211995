import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-entitlement-manager-dashboard',
  standalone: false,
  templateUrl: './entitlement-manager-dashboard.component.html',
  styleUrl: './entitlement-manager-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EntitlementManagerDashboardComponent implements OnInit {

  activeTab = 0;

  constructor(
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.spinner.hide();
  }

  changeTab(index: number) {
    this.activeTab = index
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    }
  }

}
