<div class="header-access-container">
    <div class="header-text-container">
        <h1 class="heading">Entitlement Manager</h1>
    </div>
</div>

<mat-tab-group animationDuration="0ms" [(selectedIndex)]="activeTab" (selectedIndexChange)="activeTab" mat-stretch-tabs>

    <mat-tab label="Define Resources">
        <ng-template mat-tab-label>
            <div [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (keydown)="onKeyDown($event)">
                Define Resources
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeTab(0)">Server</button>
            </mat-menu>
        </ng-template>
        <div>
            <app-server></app-server>
        </div>
    </mat-tab>

    <mat-tab label="Authorize Access">
        <ng-template mat-tab-label>
            <div [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (keydown)="onKeyDown($event)">
                Authorize Access
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeTab(1)">Test Authorization</button>
            </mat-menu>
        </ng-template>
        <div>
            <!-- content page -->
        </div>
    </mat-tab>

    <mat-tab label="Manage Users">
        <ng-template mat-tab-label>
            <div [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (keydown)="onKeyDown($event)">
                Manage Users
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeTab(2)">Users & Administrators</button>
                <button mat-menu-item (click)="changeTab(2)">Users Groups</button>
                <button mat-menu-item [matMenuTriggerFor]="addProperties" (click)="changeTab(2)">Properties</button>
            </mat-menu>
            <mat-menu #addProperties="matMenu">
                <button mat-menu-item (click)="changeTab(2)">Add New</button>
            </mat-menu>
        </ng-template>
        <div>
            <!-- content page -->
        </div>
    </mat-tab>

    <mat-tab label="Delegate Administration">
        <ng-template mat-tab-label>
            <div [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (keydown)="onKeyDown($event)">
                Delegate Administration
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeTab(3)">Administrative Groups</button>
                <button mat-menu-item (click)="changeTab(3)">Roles</button>
            </mat-menu>
        </ng-template>
        <div>
            <!-- content page -->
        </div>
    </mat-tab>

</mat-tab-group>