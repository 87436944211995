import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MaterialModule } from '../../../../material/material.module';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmDialogComponent } from '../../../../core/components/shared/confirm-dialog/confirm-dialog.component';
import { EntitlementManagerService } from '../../../services/entitlement-manager.service';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AddServerComponent } from '../add-server/add-server.component';

@Component({
  selector: 'app-server',
  standalone: true,
  imports: [MaterialModule, FormsModule],
  templateUrl: './server.component.html',
  styleUrl: './server.component.scss'
})
export class ServerComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'type', 'description', 'select'];
  public subscription$ = new Subscription()
  public optionselected = 'starts with';
  newSelectedValue: any;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  ids: string[] = [];
  name: string;
  dialogRef: MatDialogRef<any>;

  options = [
    { title: 'starts with', value: 1 },
    { title: 'contains', value: 2 },
    { title: 'doesnt contain', value: 3 },
    { title: 'ends with', value: 4 },
    { title: 'is equal to', value: 5 }
  ]

  display: boolean = false;

  constructor(
    private entitlementManagerService: EntitlementManagerService,
    public dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.getAllServers();
  }

  ngAfterViewInit(): void {// setup pagination and sorting
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  delete() {
    if (this.selection.selected.length) {
      const data = {
        title: 'Delete Server',
        content: 'Are you sure you want to delete selected server?'
      }
      const description = ['Are you sure you want to delete selected server?']
      const dialogLines = [...description];
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        panelClass: 'dialogMainContainer',
        data: { dialogTitle: data.title, dialogLines },
        autoFocus: false,
        restoreFocus: false
      });

    }
  }

  getAllServers() { // get all server on ngoninit
    const data = this.entitlementManagerService.getAllServers();
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
  }

  onPagination() {
    this.selection.clear();
  }


  changeSelection(newvalue) {// change of option selected from dropdown
    this.optionselected = newvalue;
  }

  addNew() {
    this.dialogRef = this.dialog.open(AddServerComponent, {
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      height: '80%'
    });
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every(row => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected() ?
      this.selection.deselect(...this.getPageData()) :
      this.selection.select(...this.getPageData());
  }

  onEdit(element) {
    this.dialogRef = this.dialog.open(AddServerComponent, {
      disableClose: true,
      data: { isEdit: true, data: element },
      autoFocus: false,
      restoreFocus: false,
      height: '80%'
    });
  }


}
