<h2>Server</h2>
<div class="title">
    <p> Servers host the resources that you protect with Access Manager. Search to find the Web server or
        application
        server you want to manage. If the server has not already been added to Access Manager, click Add New to
        add it
        now.
    </p>
</div>
<div class="contents">
    <b>Search for Servers where</b>
    <hr>

    <div class="input-fields">
        <div class="input-align">
            Name:
        </div>
        <mat-form-field class="form-field">
            <mat-select [(value)]="optionselected" (selectionChange)="changeSelection($event.value)">
                <mat-option *ngFor="let item of options" [value]="item.title">{{item.title}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-field">
            <input matInput [(ngModel)]="name">
        </mat-form-field>
        <div class="button-align">
            <button class="form-field" color="primary" mat-raised-button type="submit">Go</button>
            <button class="form-field" color="primary" mat-raised-button type="submit">Show
                All</button>
            <button class="form-field" color="primary" (click)="addNew()" mat-raised-button type="submit">Add
                New</button>
        </div>
    </div>

    <div class="table">
        <table mat-table [dataSource]="dataSource" matSortDirection="asc" matSort class="mat-elevation-z8">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button [matMenuTriggerFor]="action">
                        <a> Actions... </a>
                    </button>
                    <mat-menu #action="matMenu">
                        <button (click)="onEdit(element)" mat-menu-item>
                            Edit
                        </button>
                        <button (click)="onViewResource(element)" mat-menu-item>
                            View Resources
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Server </th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> Web </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox color="primary" (change)="$event ? masterToggle($event) : null"
                        [checked]="selection.hasValue() && isEntirePageSelected()"></mat-checkbox>
                    <button class="delete-btn" color="primary" (click)="delete()" mat-raised-button
                        type="submit">Delete</button>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox color="primary" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="[5]" [pageSize]="[5]" [pageSizeOptions]="[5, 10, 20]"
            (page)="onPagination()"></mat-paginator>
    </div>
</div>