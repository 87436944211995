
import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { urlType } from 'src/app/core/models/urlType';

@Injectable({
  providedIn: 'root'
})
export class EntitlementManagerService {

  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param customLogger logger service
   */

  servers = [
    { id: 1, name: 'appsdv.cartus.com', urlprefix: 'appsdv.cartus.com ', manufacturer: 'Microsoft', description: 'appsdv.cartus.com', port: '443' },
    { id: 2, name: 'appsdv1.cartus.com', urlprefix: 'appsdv1.cartus.com ', manufacturer: 'Microsoft', description: 'appsdv1.cartus.com', port: '464' },
    { id: 3, name: 'appsdv2.cartus.com', urlprefix: 'appsdv2.cartus.com ', manufacturer: 'Microsoft', description: 'appsdv2.cartus.com', port: '660' },
    { id: 4, name: 'appsdv3.cartus.com', urlprefix: 'appsdv3.cartus.com ', manufacturer: 'Microsoft', description: 'appsdv3.cartus.com', port: '770' },
    { id: 5, name: 'appsdv4.cartus.com', urlprefix: 'appsdv4.cartus.com ', manufacturer: 'Microsoft', description: 'appsdv4.cartus.com', port: '880' },
  ];

  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly customLogger: LoggerService
  ) { }

  /**
   * Return entitlement for the specified id
   * @param id id
   */
  // SEC-391: Placeholder API GET call - to be replaced with actual API call in the future
  getById(id: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/some-path/${id}`, 'Get Entitlement by ID', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get entitlement', err);
        })
      );
  }

  /**
   * Create new entitlement for the specified id
   * @param id id
   */
  // SEC-391: Placeholder API POST call - to be replaced with actual API call in the future
  createById(id: string): Observable<any> {
    return this.baseClientService
      .post<any>('/some-path', id, 'Create Entitlement', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to create entitlement', err);
        })
      );
  }

  /**
   * Update entitlement for the specified id
   * @param body body
   */
  // SEC-391: Placeholder API PUT call - to be replaced with actual API call in the future
  updateById(id: string, body: any): Observable<any> {
    return this.baseClientService
      .put<any>(`/some-path/${id}`, body, 'Update Entitlement', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to update entitlement', err);
        })
      );
  }

  catchBlock(message: string, error: any) {
    this.customLogger.error(message, error);
    return of(error);
  }

  getAllServers() {
    return this.servers;
  }

}
