import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from '../../../../material/material.module';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-server',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule],
  templateUrl: './add-server.component.html',
  styleUrl: './add-server.component.scss'
})
export class AddServerComponent implements OnInit {

  title = 'Add Server'
  buttonText = 'Save'
  isEditable: boolean = false
  admingrp = [
    { title: "Default Administrative Group" }
  ]
  serverType = [
    { title: 'Web Server' },
    { title: 'App Server' },
    { title: 'Enhanced App Server' },
  ]
  productType = [
    { title: 'Apache' },
    { title: 'Microsoft' },
    { title: 'Sun Java System Web Server' },
    { title: 'IBM HTTP Server' },
    { title: 'Domino' },
  ]

  servernameTooltip = 'The server name must match the value of the Access Manager.plugin.Web_server_name parameter in the Web server agent’s webagent.conf file.'
  servertypeTooltip = 'Web Servers host HTTP resources.  App Servers host J2EE resources.  Enhanced App Servers can make use of the J2EE resource screens for defining resources.'
  productTooltip = `If the specific product you are using on this server does not appear in this list, there may be a problem with your admingui.cfg file. For assistance, contact the person
                    in charge of RSA Access Manager configuration.`
  hostTooltip = `If the specific product you are using on this server does not appear in this list, there may be a problem with your admingui.cfg file. For assistance, contact the person in
                 charge of RSA Access Manager configuration.`
  portTooltip = 'Enter the port number of the Web server.'

  serverForm = new FormGroup({
    id: new FormControl(),
    servername: new FormControl('', [Validators.required]),
    servertype: new FormControl('Web Server', [Validators.required]),
    productType: new FormControl('', [Validators.required]),
    hostName: new FormControl('', [Validators.required]),
    portNo: new FormControl('80', [Validators.required]),
    description: new FormControl(''),
    admingrp: new FormControl('Default Administrative Group', [Validators.required]),
    visibility: new FormControl(''),
  })


  constructor(
    public dialogRef: MatDialogRef<AddServerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const data = this.data;
    if (data.isEdit === true) {
      this.title = 'Edit Server';
      this.buttonText = 'Update';
      this.isEditable = true;
      this.assignValues()
    }
  }

  assignValues() {
    this.serverForm.patchValue({
      id: this.data.data.id,
      servername: this.data.data.name,
      servertype: 'Web Server',
      productType: this.data.data.manufacturer,
      hostName: this.data.data.urlprefix,
      portNo: this.data.data.port,
      description: this.data.data.description,
      admingrp: 'Default Administrative Group',
      visibility: 'public'
    });
  }

  onSubmit() {

  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
