<div class="title">
    <h2> {{title}}</h2>
    <a href="#" class="close-icon" (click)="onCancel()" title="close">
        <mat-icon>close</mat-icon>
    </a>
</div>
<div class="description">
    <p> Servers host resources that Access Manager protects. You must add the server to Access Manager before
        you can protect it and the resources it hosts.
    </p>
</div>

<div class="second-tab-content">
    <form [formGroup]="serverForm" (ngSubmit)="onSubmit()">
        <aside id="info-block" aria-label="Server Basic">
            <section class="file-marker">
                <div>
                    <div class="box-title">
                        <b> Server Basics</b>
                    </div>
                    <div class="box-contents">
                        <label for="servername"> Server Name: </label>
                        <mat-form-field class="form-field">
                            <input formControlName="servername" matInput required>
                            <mat-error
                                *ngIf="serverForm.get('servername').touched && serverForm.get('servername').errors?.required">
                                Name is required
                            </mat-error>
                        </mat-form-field>
                        <mat-icon [matTooltip]="servernameTooltip" matTooltipPosition="right" class="hint">
                            help_outline
                        </mat-icon>

                        <div>
                            <label for="servertype">Server Type:</label>
                            <mat-form-field class="form-field">
                                <mat-select formControlName="servertype">
                                    <mat-option *ngFor="let item of serverType"
                                        [value]="item.title">{{item.title}}</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="serverForm.get('servertype').touched && serverForm.get('servertype').errors?.required">
                                    Server type is required
                                </mat-error>
                            </mat-form-field>
                            <mat-icon [matTooltip]="servertypeTooltip" matTooltipPosition="right" class="hint">
                                help_outline
                            </mat-icon>
                        </div>

                        <div>
                            <label for="productType">Product:</label>
                            <mat-form-field class="form-field">
                                <mat-select formControlName="productType">
                                    <mat-option *ngFor="let item of productType"
                                        [value]="item.title">{{item.title}}</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="serverForm.get('productType').touched && serverForm.get('productType').errors?.required">
                                    Product type is required
                                </mat-error>
                            </mat-form-field>
                            <mat-icon [matTooltip]="productTooltip" matTooltipPosition="right" class="hint">
                                help_outline
                            </mat-icon>
                        </div>

                        <div>
                            <label for="hostName"> Host Name: </label>
                            <mat-form-field class="form-field">
                                <input formControlName="hostName" matInput required>
                                <mat-error
                                    *ngIf="serverForm.get('hostName').touched && serverForm.get('hostName').errors?.required">
                                    Host Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-icon [matTooltip]="hostTooltip" matTooltipPosition="right" class="hint">
                                help_outline
                            </mat-icon>
                        </div>

                        <div>
                            <label for="portNo"> Port: </label>
                            <mat-form-field class="form-field">
                                <input formControlName="portNo" matInput required>
                                <mat-error
                                    *ngIf="serverForm.get('portNo').touched && serverForm.get('portNo').errors?.required">
                                    Port is required
                                </mat-error>
                            </mat-form-field>
                            <mat-icon [matTooltip]="portTooltip" matTooltipPosition="right" class="hint">
                                help_outline
                            </mat-icon>
                        </div>

                        <div>
                            <label for="description"> Description: </label>
                            <mat-form-field class="form-field">
                                <input formControlName="description" matInput required>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </section>
        </aside>
        <div class="second-block">
            <aside id="info-block" aria-label="Administrative Block">
                <section class="file-marker">
                    <div>
                        <div class="box-title">
                            <b> Administrative Block</b>
                        </div>
                        <div class="box-contents">
                            <div>
                                <label for="admingrp">Administrative Group:</label>
                                <mat-form-field class="form-field">
                                    <mat-select formControlName="admingrp">
                                        <mat-option *ngFor="let item of admingrp"
                                            [value]="item.title">{{item.title}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="serverForm.get('servertype').touched && serverForm.get('servertype').errors?.required">
                                        Data type is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-icon [matTooltip]="servertypeTooltip" matTooltipPosition="right" class="hint">
                                    help_outline
                                </mat-icon>
                            </div>

                            <div class="radio-grp">
                                <span></span>
                                <label for="visibility">Visibility:</label>
                                <mat-radio-group formControlName="visibility" class="radio-btn">
                                    <mat-radio-button id="public-radio" value="public" [checked]="true"
                                        color="primary">Public
                                        (visible
                                        to
                                        all
                                        administrators)</mat-radio-button>
                                    <mat-radio-button id="private-radio" value="private" color="primary">Private
                                        (visible only to
                                        administrators of
                                        this administrative group)</mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                    </div>
                </section>
            </aside>
        </div>

        <div *ngIf="!isEditable" class="disclaimer">
            <p>To add additional servers, click <b>Save & Add Another.</b></p>
        </div>
        <div class="buttons">
            <button mat-raised-button color="primary" type="submit">{{buttonText}}</button>
            <button *ngIf="!isEditable" class="form-field" color="primary" name="button_1" mat-raised-button>Save &
                Add Another</button>
            <button class="form-field" color="primary" name="button_1" (click)="onCancel()"
                mat-raised-button>Cancel</button>
        </div>
    </form>
</div>